<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.approve_visit_report_title') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <!-- fiscal year -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <b-form-group
                label-for="fiscal_year_id"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    id="clone_info_id"
                    :options="fiscalYearList"
                    v-model="search.fiscal_year_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
          </b-col>
          <!-- garden -->
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Garden Name" vid="garden_id" rules="required|min_value:1">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="garden_id">
                <template v-slot:label>
                  {{ $t('teaGardenConfig.garden_name') }}
                </template>
                <v-select
                  id="garden_id"
                  plain
                  v-model="search.garden_id"
                  :options="getGardenList"
                  :reduce="op => op.value"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :placeholder="$t('globalTrans.select')"
                  >
                  </v-select>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- from date -->
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="From Date" vid="from_date" rules="required">
                <b-form-group
                    label-for="from_date"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.from_date')}}
                  </template>
                  <date-picker
                    id="from_date"
                    v-model="search.from_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <!-- to date -->
          <b-col xs="12" sm="12" md="4">
              <ValidationProvider name="To Date" vid="to_date" rules="required">
                <b-form-group
                    label-for="to_date"
                    slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{$t('globalTrans.to_date')}}
                  </template>
                  <date-picker
                    id="to_date"
                    v-model="search.to_date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                  >
                  </date-picker>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.approve_visit_report_title') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add mx-2" @click="pdfExport"><i class="ri-printer-fill text-right"></i> {{$t('globalTrans.export_pdf') }}</a>
      </template>
      <template v-slot:body>
         <b-overlay :show="loadingState">
          <b-row>
             <b-col md="12" class="table-responsive">
              <b-table-simple hover small bordered responsive style="width:100%">
                <b-thead>
                  <b-tr>
                    <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center">{{ $t('teaGardenService.application_id') }}</b-th>
                    <b-th class="text-center">{{ $t('teaGardenConfig.tea_garden_name') }}</b-th>
                    <b-th class="text-center">{{ $t('teaGardenBtriService.visit_date') }}</b-th>
                    <b-th class="text-center">{{ $t('globalTrans.note') }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template >
                    <b-tr style="border-left: none" v-for="(item, index) in schedule_garden_visit_list" :key="'schedule_visit_' + index">
                      <b-td class="text-center" >{{ $n(index + 1) }}</b-td>
                      <b-td class="text-center" >{{ item.application_no}}</b-td>
                      <b-td class="text-center" >{{getGardenName(item.garden_id)}}</b-td>
                      <b-td class="text-center" >{{ item.visit_date | dateFormat }}</b-td>
                      <b-td class="text-center" >{{ currentLocal === 'en' ? item.note_en : item.note_bn}}</b-td>
                    </b-tr>
                    <b-tr style="border-left: none" v-for="(item, index) in online_garden_visit_list" :key="'online_garden_visit_' + index">
                      <b-td class="text-center" >{{Object.keys(schedule_garden_visit_list).length > 0 ? $n((parseInt(Object.keys(schedule_garden_visit_list).length) + index + 1)) : $n(index + 1) }}</b-td>
                      <b-td class="text-center" >{{ item.application_no}}</b-td>
                      <b-td class="text-center" >{{getGardenName(item.garden_id)}}</b-td>
                      <b-td class="text-center" >{{ item.visit_date | dateFormat }}</b-td>
                      <b-td class="text-center" >{{ currentLocal === 'en' ? item.visit_note_en : item.visit_note_bn}}</b-td>
                    </b-tr>
                  </template>
                  <template v-if="Object.keys(schedule_garden_visit_list).length === 0 && Object.keys(online_garden_visit_list).length === 0">
                    <b-tr>
                      <b-td colspan="5" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                    </b-tr>
                  </template>
                </b-tbody>
              </b-table-simple>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <!-- visit garden confirm mdoal -->
   <b-modal id="modal-visit-garden-confirm" size="lg" :title="visitGardenConfirmTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <OnlineVisitConfirm :id="editItemId" :key="editItemId"/>
    </b-modal>
      <!-- details modal -->
    <b-modal id="modal-form-details" size="lg" :title="detailsFormTitle" hide-footer :ok-title="$t('globalTrans.close')"
        ok-only ok-variant="danger">
        <Details :id="editItemId" :key="editItemId" ref="details" />
    </b-modal>
  </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { advisoryVisitApproveReportList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'
import OnlineVisitConfirm from './OnlineVisitConfirm'
// from garden advisory visit details
import Details from '../../../../garden/pages/advisory-visit/Details.vue'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    OnlineVisitConfirm,
    Details
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          fiscal_year_id: this.$store.state.currentFiscalYearId,
          limit: 10
        },
        online_garden_visit_list: [],
        schedule_garden_visit_list: [],
      labelData: [],
      items: [],
      viewitemId: 0,
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    authUser: function () {
      return this.$store.state.Auth.authUser
    },
    currentLocal () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    getGardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_id' },
          { key: 'tentative_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'application_no' },
          { key: 'garden_id' },
          { key: 'tentative_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    visitGardenConfirmTitle () {
      return (this.editItemId === 0) ? this.$t('teaGarden.online_adv_visit') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGarden.online_adv_visit') + ' ' + this.$t('globalTrans.modify')
    },
    detailsFormTitle () {
      return this.$t('teaGarden.online_adv_visit') + ' ' + this.$t('globalTrans.details')
    },
    statusList () {
        const arr = [
          { value: 1, text: this.$t('globalTrans.draft'), text_en: this.$t('globalTrans.draft', 'en'), text_bn: this.$t('globalTrans.draft', 'bn') },
          { value: 2, text: this.$t('globalTrans.pending'), text_en: this.$t('globalTrans.pending', 'en'), text_bn: this.$t('globalTrans.pending', 'bn') },
          { value: 3, text: this.$t('globalTrans.confirm'), text_en: this.$t('globalTrans.confirm', 'en'), text_bn: this.$t('globalTrans.confirm', 'bn') },
          { value: 4, text: this.$t('globalTrans.visited'), text_en: this.$t('globalTrans.visited', 'en'), text_bn: this.$t('globalTrans.visited', 'bn') }
        ]
        return arr
    }
  },
  methods: {
    visitGardenConfirmModalOpen (id) {
        this.editItemId = id
    },
    details (item) {
      this.editItemId = item.id
    },
    searchData () {
      this.loadData()
    },
    getFiscalYear (yearId) {
        const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    getStatusName (status) {
      const obj = this.statusList.find(item => item.value === parseInt(status))
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    getGardenName (id) {
      const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === id)
      return this.currentLocal === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, advisoryVisitApproveReportList, params)
      if (result.success) {
         this.online_garden_visit_list = result.online_garden_visit_list
         this.schedule_garden_visit_list = result.schedule_garden_visit_list
      } else {
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    async pdfExport () {
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5 }, this.search)
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 22)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, advisoryVisitApproveReportList, params)
        var blob = new Blob([result], {
            type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
